import React from "react";

const Feeds = (props: any) => {
    return (
        <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M13.3479 6.58658C13.5507 6.38246 13.5507 6.05155 13.3479 5.84744L9.0498 1.52115C8.61004 1.07842 8.24323 0.709225 7.91292 0.455552C7.56429 0.187801 7.19592 0 6.74997 0C6.30406 0 5.9357 0.187801 5.58705 0.455552C5.25674 0.709232 4.88998 1.07842 4.45015 1.52116L0.152079 5.84744C-0.050693 6.05155 -0.050693 6.38246 0.152079 6.58658C0.354854 6.79066 0.683608 6.79066 0.886386 6.58658L1.18384 6.28716V8.57486C1.18384 10.8205 2.71945 12.7707 4.89147 13.2835C6.11389 13.5722 7.38607 13.5722 8.60855 13.2835C10.7805 12.7707 12.3161 10.8205 12.3161 8.57486V6.28715L12.6136 6.58658C12.8164 6.79066 13.1451 6.79066 13.3479 6.58658Z" fill="currentColor" stroke="currentColor" />
        </svg>
    );
};

export default Feeds;