import React from "react";

const Artist = (props: any) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.8893 1.48175C13.6849 1.27737 13.3783 1.27737 13.174 1.48175L11.7944 2.86131L11.4878 2.55474L11.1813 2.24818L12.5097 0.868613C12.7141 0.664234 12.7141 0.357664 12.5097 0.153285C12.3054 -0.0510949 11.9988 -0.0510949 11.7944 0.153285L10.3637 1.58394C9.64842 1.07299 8.77981 0.868613 7.96229 1.0219C6.94039 1.17518 6.07178 1.78832 5.61192 2.65693C5.35645 3.11679 4.99878 3.42336 4.48784 3.62774L2.49513 4.39416C1.26886 4.90511 0.400244 5.92701 0.093675 7.20438C-0.212894 8.58394 0.24696 10.0146 1.26886 11.0365L3.00608 12.7737C3.77251 13.5401 4.8455 14 5.96959 14C6.22506 14 6.53163 13.9489 6.78711 13.8978C8.11557 13.6423 9.13747 12.7737 9.59732 11.4964L10.3637 9.50365C10.5681 9.0438 10.8747 8.63504 11.3346 8.37956C12.2543 7.86861 12.8163 7.05109 12.9696 6.0292C13.0718 5.16058 12.8674 4.29197 12.4075 3.62774L13.8893 2.24818C14.0937 2.0438 14.0937 1.68613 13.8893 1.48175ZM5.91849 10.6788C5.8163 10.781 5.66302 10.8321 5.56083 10.8321C5.45864 10.8321 5.30535 10.781 5.20316 10.6788L3.31265 8.78832C3.10827 8.58394 3.10827 8.27737 3.31265 8.07299C3.51703 7.86861 3.8236 7.86861 4.02798 8.07299L5.91849 9.9635C6.12287 10.1679 6.12287 10.4745 5.91849 10.6788ZM9.39294 7.40876C9.03528 7.76642 8.52433 7.9708 8.01338 7.9708C7.50243 7.9708 6.99149 7.76642 6.63382 7.40876C6.27616 7.0511 6.07178 6.54015 6.07178 6.0292C6.07178 5.51825 6.27616 5.0073 6.63382 4.64963C7.40024 3.88321 8.67762 3.88321 9.44404 4.64963C9.8017 5.0073 10.0061 5.51825 10.0061 6.0292C10.0061 6.54015 9.75061 7 9.39294 7.40876Z" fill="currentColor" />

    </svg>
  );
};

export default Artist;
