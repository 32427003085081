import React from "react";

const Wallet = (props: any) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.93674 0.145893L5.21298 4.1585H3.4892C3.20309 4.1585 2.92414 4.17996 2.65234 4.23718L3.3676 2.52056L3.39621 2.45618L3.43913 2.34174C3.46059 2.29167 3.47489 2.24876 3.49635 2.213C4.32605 0.288945 5.25589 -0.311874 6.93674 0.145893Z" fill="currentColor" />
      <path d="M11.793 4.35119L11.7786 4.34404C11.3495 4.22244 10.9132 4.15807 10.4697 4.15807H5.99219L7.60152 0.417256L7.62298 0.367188C7.72312 0.402951 7.83041 0.453019 7.9377 0.488782L9.51842 1.15397C10.3982 1.51876 11.0133 1.89785 11.3924 2.35561C11.4568 2.44144 11.514 2.52012 11.5712 2.61311C11.6356 2.71324 11.6857 2.81338 11.7143 2.92067C11.7429 2.98504 11.7643 3.04226 11.7786 3.10664C11.8859 3.47142 11.8931 3.88627 11.793 4.35119Z" fill="currentColor" />
      <path d="M7.35156 11.1915H7.53038C7.74496 11.1915 7.92377 10.9984 7.92377 10.7624C7.92377 10.462 7.83794 10.419 7.65197 10.3475L7.35156 10.2402V11.1915Z" fill="currentColor" />
      <path d="M11.4799 5.37352C11.1581 5.28054 10.8219 5.23047 10.4714 5.23047H3.49047C3.00409 5.23047 2.54633 5.32345 2.11717 5.50942C0.872618 6.04586 0 7.28326 0 8.72093V10.1157C0 10.2874 0.0143052 10.4519 0.035763 10.6235C0.19312 12.8981 1.40906 14.114 3.68359 14.2642C3.8481 14.2857 4.01261 14.3 4.19143 14.3H9.77048C12.4169 14.3 13.8117 13.0411 13.9476 10.5377C13.9548 10.4018 13.9619 10.2587 13.9619 10.1157V8.72093C13.9619 7.14021 12.9105 5.80982 11.4799 5.37352ZM7.89649 9.65077C8.22551 9.76521 8.66897 10.0084 8.66897 10.7666C8.66897 11.4175 8.16114 11.9396 7.53171 11.9396H7.35289V12.097C7.35289 12.3044 7.18838 12.4689 6.98096 12.4689C6.77353 12.4689 6.60902 12.3044 6.60902 12.097V11.9396H6.54465C5.858 11.9396 5.29293 11.3602 5.29293 10.645C5.29293 10.4376 5.45744 10.273 5.66488 10.273C5.8723 10.273 6.03681 10.4376 6.03681 10.645C6.03681 10.9454 6.2657 11.1957 6.54465 11.1957H6.60902V9.98695L6.06542 9.79383C5.7364 9.67938 5.29293 9.43619 5.29293 8.67802C5.29293 8.02713 5.80078 7.50499 6.43021 7.50499H6.60902V7.34763C6.60902 7.14021 6.77353 6.9757 6.98096 6.9757C7.18838 6.9757 7.35289 7.14021 7.35289 7.34763V7.50499H7.41727C8.10392 7.50499 8.66897 8.08435 8.66897 8.79961C8.66897 9.00704 8.50446 9.17155 8.29704 9.17155C8.08961 9.17155 7.9251 9.00704 7.9251 8.79961C7.9251 8.4992 7.69622 8.24886 7.41727 8.24886H7.35289V9.45765L7.89649 9.65077Z" fill="currentColor" />
      <path d="M6.03906 8.67916C6.03906 8.97957 6.12489 9.02248 6.31086 9.09401L6.61127 9.2013V8.25H6.43246C6.21073 8.25 6.03906 8.44312 6.03906 8.67916Z" fill="currentColor" />
    </svg>
  );
};

export default Wallet;
