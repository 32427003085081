import React from "react";

const More = (props: any) => {
  return (
    <svg
      width="16"
      height="5"
      viewBox="0 0 16 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.875 2.4082C8.875 2.58126 8.82368 2.75043 8.72754 2.89433C8.63139 3.03822 8.49473 3.15037 8.33485 3.2166C8.17496 3.28283 7.99903 3.30015 7.8293 3.26639C7.65956 3.23263 7.50365 3.14929 7.38128 3.02692C7.25891 2.90455 7.17558 2.74864 7.14181 2.57891C7.10805 2.40917 7.12538 2.23324 7.1916 2.07335C7.25783 1.91347 7.36998 1.77681 7.51388 1.68067C7.65777 1.58452 7.82694 1.5332 8 1.5332C8.23206 1.5332 8.45463 1.62539 8.61872 1.78948C8.78281 1.95358 8.875 2.17614 8.875 2.4082V2.4082Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 2.4082C15 2.58126 14.9487 2.75043 14.8525 2.89433C14.7564 3.03822 14.6197 3.15037 14.4598 3.2166C14.3 3.28283 14.124 3.30015 13.9543 3.26639C13.7846 3.23263 13.6287 3.14929 13.5063 3.02692C13.3839 2.90455 13.3006 2.74864 13.2668 2.57891C13.2331 2.40917 13.2504 2.23324 13.3166 2.07335C13.3828 1.91347 13.495 1.77681 13.6389 1.68067C13.7828 1.58452 13.9519 1.5332 14.125 1.5332C14.3571 1.5332 14.5796 1.62539 14.7437 1.78948C14.9078 1.95358 15 2.17614 15 2.4082V2.4082Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default More;
