export const transactions = [
  // {
  //   type: "dr",
  //   token: "$LZR",
  //   to: "Nnenna Oke",
  //   price: 92.01,
  // },
  // {
  //   type: "cr",
  //   token: "$DAVIDO",
  //   from: "Moses Franklin",
  //   price: 24.77,
  // },
  // {
  //   type: "dr",
  //   token: "$LZR",
  //   to: "Anderson Gibbs",
  //   price: 109.2,
  // },
];
