import React from "react";

const Speaker = (props: any) => {
    return (
        <svg
            width="11"
            height="14"
            viewBox="0 0 11 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5.26775 9.57065C6.06067 9.57065 6.70346 8.92786 6.70346 8.13493C6.70346 7.34201 6.06067 6.69922 5.26775 6.69922C4.47482 6.69922 3.83203 7.34201 3.83203 8.13493C3.83203 8.92786 4.47482 9.57065 5.26775 9.57065Z" fill="currentColor" />
            <path d="M10.05 0H0.478571C0.191429 0 0 0.191429 0 0.478571V12.9214C0 13.2086 0.191429 13.4 0.478571 13.4H10.05C10.3371 13.4 10.5286 13.2086 10.5286 12.9214V0.478571C10.5286 0.191429 10.3371 0 10.05 0ZM5.26429 11.9643C3.15857 11.9643 1.43571 10.2414 1.43571 8.13571C1.43571 6.03 3.15857 4.30714 5.26429 4.30714C7.37 4.30714 9.09286 6.03 9.09286 8.13571C9.09286 10.2414 7.37 11.9643 5.26429 11.9643ZM7.65714 4.30714C6.84357 4.30714 6.22143 3.685 6.22143 2.87143C6.22143 2.05786 6.84357 1.43571 7.65714 1.43571C8.47071 1.43571 9.09286 2.05786 9.09286 2.87143C9.09286 3.685 8.47071 4.30714 7.65714 4.30714Z" fill="currentColor" />
        </svg>
    );
};

export default Speaker;